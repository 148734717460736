<template>
  <div class="patients">
    <Loader v-if="pageLoading" text="Getting data..." />
    <div v-else>
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-on:keyup.enter="getInfo(search)"
              v-model="search"
              label="Search"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn color="primary" class="mr-4" @click="getInfo(search)">
              Search
            </v-btn>
            <v-btn class="mr-4" @click="getInfo()"> All </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Account #</th>
            <th class="text-left">Patient Name</th>
            <th class="text-left">Date of birth</th>
            <th class="text-left">Cellphone</th>
            <th class="text-left">Email</th>
          </tr>
        </thead>
        <tbody v-if="patientsList.length">
          <template v-for="(patient, index) in patientsList">
            <tr
              class="patients__btn"
              v-on:click="showPatientDetails(patient)"
              :key="index"
              v-bind:class="{ _active: patientItemView === index }"
            >
              <td>{{ patient.patient.patient_id }}</td>
              <td>
                {{ patient.patient.firstname }} {{ patient.patient.lastname }}
              </td>
              <td>{{ patient.patient.dob }}</td>
              <td>{{ patient.patient.cellphone }}</td>
              <td>{{ patient.patient.email }}</td>
            </tr>
          </template>
        </tbody>
        <tbody v-if="!patientsList.length">
          <tr class="patients__btn">
            <td colspan="5">No Records</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Loader from '@/components/loader/loader.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'Patients',
  components: {
    Loader,
  },
  data() {
    return {
      patientsList: [],
      loading: false,
      disableEdit: true,
      patientEdit: false,
      patientItemView: false,
      tempPatient: {},
      search: '',
      pageLoading: false,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
  },
  mounted() {
    this.getInfo();
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.getInfo();
    },
  },
  methods: {
    async getInfo(search) {
      this.pageLoading = true;
      const URL = '/proxy/get-customer-patients';
      const customerID =
        this.customer.customer_id === 0
          ? this.selectedCustomer.customer_id
          : this.customer.customer_id;

      try {
        const resp = await window.axios.post(URL, {
          customer_id: customerID,
          search: search,
        });
        if (!resp.data || !resp.data.success)
          throw new Error('Request failed. Check backend logs');
        this.patientsList = resp.data.patients;
      } catch (e) {
        console.error(e);
      } finally {
        this.pageLoading = false;
      }
    },
    getSearch(event) {
      let form = new FormData(event.target);
      this.getInfo(form.get('search'));
    },
    showPatientDetails(data) {
      const patient = data.patient;
      if (data.isPatientInTestList) {
        this.$router.push({
          path: `/payments/patients/${patient.patient_id}?pid=${patient.pk}&customer_id=${patient.customer_id}`,
        });
      } else {
        this.$router.push({ path: `/patients/${data.patient.pk}` });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
@import './_buttons.scss';
@import './patients.scss';
</style>
